import React from 'react';
import {faBolt, faTasks, faQuestion} from '@fortawesome/free-solid-svg-icons';
import {faWordpress} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Translate} from '../../components/translations';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import BrokenLinkCorrectorItem from '../../components/brokenLinkCorrectorItem';
import SetUpSteps from '../../components/setUpSteps';
import FAQItem from '../../components/FAQ';

import step1Img from '../../img/process-steps/setup_img1.jpg';
import step2Img from '../../img/process-steps/setup_img2.jpg';
import step3Img from '../../img/process-steps/setup_img3.jpg';
import logo from '../../img/Hexometer-Logo-square.svg';
import plug from '../../img/plug.svg';

const BrokenLinkCorrector = () => (
  <Layout>
    <PageHero title="BROKEN_LINKS_REPAIR_TITLE" />
    <Section>
      <div className="col-xs-12 col-lg-8">
        <Translate name="BROKEN_LINKS_REPAIR_PAGE_CONTENT" />
      </div>
      <div className="col-xs-12 col-lg-4 d-flex justify-content-center">
        <img src={logo} alt="Hexometer.com" width={128} height={128} className="mr-2" />
        <img src={plug} alt="plug" width={64} height={64} className="ml-2 mr-2 mt_30" />
        <FontAwesomeIcon icon={faWordpress} size="8x" className="ml-2" color="#26749c" />
      </div>

      <div className="col-md-12 pl_50 pr_50 pt_50">
        <BrokenLinkCorrectorItem
          icon="bolt"
          title={<Translate name="BROKEN_LINKS_REPAIR_CONTENT_TITLE_ONE" />}
          text={<Translate name="BROKEN_LINKS_REPAIR_FAST_INSTANT_TEXT" />}
        />
        <BrokenLinkCorrectorItem
          icon="tasks"
          title={<Translate name="BROKEN_LINKS_REPAIR_CONTENT_TITLE_TWO" />}
          text={<Translate name="BROKEN_LINKS_REPAIR_EASY_SETUP_TEXT" />}
        />
        <div className="pt_50 pb_50">
          <h4 className="text-center">
            <Translate name="BROKEN_LINKS_REPAIR_EASY_SETUP_SUBTITLE" />
          </h4>
          <SetUpSteps
            src={step1Img}
            alt={'Create Account'}
            link={'https://dash.hexometer.com'}
            number={1}
            text={<Translate name="BROKEN_LINKS_REPAIR_EASY_SETUP_POINT_ONE_TEXT" />}
            topLine={true}
          />
          <SetUpSteps
            src={step2Img}
            alt={'Download broken links repair plugin'}
            link={'https://wordpress.org/plugins/broken-link-repair/'}
            number={2}
            text={<Translate name="BROKEN_LINKS_REPAIR_EASY_SETUP_POINT_TWO_TEXT" />}
            middleLine={true}
          />
          <SetUpSteps
            src={step3Img}
            alt={'Activate broken links repair plugin'}
            link={'https://dash.hexometer.com'}
            number={3}
            text={<Translate name="BROKEN_LINKS_REPAIR_EASY_SETUP_POINT_THREE_TEXT" />}
            bottomLine={true}
          />
        </div>
        <BrokenLinkCorrectorItem
          icon={faQuestion}
          title={<Translate name="BROKEN_LINKS_REPAIR_CONTENT_TITLE_THREE" />}
        />
        <div className="pl_50 pr_50">
          <FAQItem
            title={<Translate name="BROKEN_LINKS_REPAIR_FAQ_QUESTION_ONE" />}
            text={<Translate name="BROKEN_LINKS_REPAIR_FAQ_ANSWER_ONE" />}
          />
          <FAQItem
            title={<Translate name="BROKEN_LINKS_REPAIR_FAQ_QUESTION_TWO" />}
            text={<Translate name="BROKEN_LINKS_REPAIR_FAQ_ANSWER_TWO" />}
          />
        </div>
      </div>
    </Section>
  </Layout>
);

export default BrokenLinkCorrector;
