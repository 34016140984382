import React, { FC } from 'react';
import { SetUpStepsProps } from '../../types';
import SetUpStepItem from './setUpStepsItem';
import './styles.scss';

const SetUpSteps: FC<SetUpStepsProps> = ({ src, number, text, topLine, middleLine, bottomLine, alt, link }) => (
  <div className="container_steps">
    <div className="setup_inner">
      <SetUpStepItem src={src} number={number} text={text} topLine={topLine} middleLine={middleLine} bottomLine={bottomLine} link={link} alt={alt} />
    </div>
  </div>
);

export default SetUpSteps;
