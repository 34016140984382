import React, {FC} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BrokenLinkCorrectorProps} from '../../types';
import './styles.scss';

const BrokenLinkCorrectorItem: FC<BrokenLinkCorrectorProps> = ({icon, title, text}) => {
  return (
    <div className="scree_feature_item">
      <div className="media">
        <div className="media-left">
          <div className="icon">{icon && <FontAwesomeIcon icon={icon} />}</div>
        </div>
        <div className="media-body">{title}</div>
      </div>
      <p>{text}</p>
    </div>
  );
};

export default BrokenLinkCorrectorItem;
