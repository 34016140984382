import React, { FC } from 'react';
import { SetUpStepsProps } from '../../../types';
import classNames from 'classnames';
import './styles.scss';

const SetUpStepItem: FC<SetUpStepsProps> = ({ src, number, text, topLine, middleLine, bottomLine, alt, link }) => (
  <div className="setup_item row">
    <div className="col-md-5 setup_img">
      <a href={link} target='_blank' rel='noopener'><img
        width={117}
        height={198}
        src={src}
        className="attachment-full size-full"
        alt={alt}
      /></a>
    </div>
    <div className="col-md-2 s_number">
      <div className="round">{number}</div>
    </div>
    <div className="col-md-5">
      <div className="setup_content">
        <p>{text}</p>
      </div>
    </div>
    <div className={classNames({'line_top': topLine}, {'line_middle': middleLine}, {'line_bottom': bottomLine})}/>
  </div>
);

export default SetUpStepItem;
